import * as React from 'react'
import { Component } from 'react'
import { RouteComponentProps } from "react-router-dom";
import { SecondaryTitle, SubmitBtns } from '@app/components';
import { withRouter, GLForm, GLFormComponentProps, connect, StateType, FormHelper } from 'gl-commonui';
import { Checkbox, Col } from 'antd-min';
import { SchoolLocale } from '@app/locales/localeid';
import { FormattedMessage } from 'react-intl';
import { SchoolClassService } from '@app/service/class';
import { lazyInject, TYPES, GSSchoolAction, onSubmit } from '@app/util';
import { goToStudents, promotestudents } from '@app/states/school/class';

interface StudentsPromoteProps {
    goToStudents: () => void
    promotestudents?: (d) => void
}
interface StudentsPromoteStates {
    students: any
    checkAll: boolean
    checkedList: string[]
}

@withRouter
@GLForm.create()
@connect(({ schoolClass: { list } }: StateType) => ({
    list
}), {
    goToStudents,
    promotestudents,
})
export class StudentsPromotePage extends Component<RouteComponentProps<any> & GLFormComponentProps & StudentsPromoteProps, StudentsPromoteStates> {
    @lazyInject(TYPES.ISchoolClassService)
    schoolClass: SchoolClassService
    constructor(props, context) {
        super(props);
        this.state = {
            students: [],
            checkAll: false,
            checkedList: [],
        }
    }
    componentDidMount() {
        const { match: { params } } = this.props;
        const selectedobj = JSON.parse(sessionStorage.getItem("selectionSchoolClassId"));
        sessionStorage.removeItem("selectionSchoolClassId");
        this.schoolClass.getStudentsBy(null, {schoolId: params.schoolId, id: selectedobj && selectedobj.selectedId})
            .then((data: any) => {
                this.setState({
                    students: data && data.data
                });
            });
    }
    renderStudents(item, index) {
        let isChecked = this.state.checkedList.findIndex(id => id == item.id) > -1 ? true : false;
        return <Col key={index} span={8}>
            <Checkbox className="studentname" key={item.id} value={item.id} defaultChecked={isChecked} checked={isChecked}>
                <span title={item.name}>{item.name}</span>
            </Checkbox>
        </Col>
    }
    onChange(checkedList) {
        this.setState({
            checkedList: checkedList,
            checkAll: checkedList.length === this.state.students.length,
        });
    } 
    onCheckAllChange(e) {
        const checkedAllIds = this.state.students.map((student) => {
            return student.id;
        });
        this.setState({
            checkedList: e.target.checked ? checkedAllIds : [],
            checkAll: e.target.checked,
        });
    }
    onSubmit(e) {
        e.preventDefault();
        const { promotestudents, match: { params } } = this.props;
        promotestudents({ ...params, studentIds: this.state.checkedList, isPromotion: true});
    }
    render() {
        const { form, goToStudents } = this.props;
        const students = this.state.students.map((item, index) => {
            return this.renderStudents(item, index);
        })
        return <div className='content-layout class-crud class-crud-new'>
            <div>
                <SecondaryTitle title={SchoolLocale.ClassPromoteTitle} >
                </SecondaryTitle>
                <GLForm form={form} onSubmit={(e) => this.onSubmit(e)}>
                    <div style={{ minHeight: "300px"}}>
                        <Checkbox
                            onChange={this.onCheckAllChange.bind(this)}
                            checked={this.state.checkAll}
                        >
                            <FormattedMessage id={SchoolLocale.ClassPromoteAllStudents} />
                        </Checkbox>
                        <div className="studenttitle"><FormattedMessage id={SchoolLocale.ClassPromoteStudentsTitle} /></div>
                        <Checkbox.Group className="studentlist" value={this.state.checkedList} onChange={this.onChange.bind(this)} >
                            {students}
                        </Checkbox.Group>
                    </div>
                    <SubmitBtns onCancel={goToStudents} submitAction={GSSchoolAction.ClassPromotion} />
                </GLForm>
            </div>
        </div>
    }
}